import { cloneDeep, get, set } from 'lodash';
import React, {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useReducer,
} from 'react';
import Cookies from 'universal-cookie';
import * as client from './client';

const AuthContext = createContext();
const cookies = new Cookies();

const initReducer = () => ({
    loggedIn: new Date(cookies.get('sessionExpiry')) > new Date(),
    products: {},
    preferences: null,
    stripe: {},
    user: undefined,
});

const reducer = (state, { action, value }) => {
    switch (action) {
        case 'setLogin': {
            return { ...state, loggedIn: value };
        }
        case 'setPaymentMethods': {
            return { ...state, paymentMethods: value };
        }
        case 'setPreference': {
            const { path, value: desiredValue } = value;
            return {
                ...state,
                preferences: set(
                    cloneDeep(state.preferences || {}),
                    path,
                    desiredValue
                ),
            };
        }
        case 'setPreferences': {
            return { ...state, preferences: value };
        }
        case 'setProducts': {
            return {
                ...state,
                products: {
                    ...state.products,
                    [value.partnerId]: value.products,
                },
            };
        }
        case 'setStripeStatus': {
            return {
                ...state,
                stripe: {
                    ...state.stripe,
                    [value.partnerId]: value.status,
                },
            };
        }
        case 'setUser': {
            return { ...state, user: value };
        }
        default:
            throw new Error(`Unexpected action type ${action}`);
    }
};

export const AuthProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, props, initReducer);

    useEffect(() => {
        const msUntilLogout =
            new Date(cookies.get('sessionExpiry')) - new Date();
        const autoLogout = setTimeout(() => {
            const isLoggedIn =
                new Date(cookies.get('sessionExpiry')) > new Date();
            if (isLoggedIn) return;
            dispatch({ action: 'setLogin', value: false });
            dispatch({ action: 'setUser', value: null });
        }, msUntilLogout);
        return () => clearTimeout(autoLogout);
    }, [cookies.get('sessionExpiry')]);

    const value = useMemo(() => [state, dispatch], [state]);
    return <AuthContext.Provider value={value} {...props} />;
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used inside an AuthProvider');
    }

    const [state, dispatch] = context;
    const { requestReset } = client;

    const fetchPartnerProducts = ({ partnerId, signal }) =>
        client.fetchPartnerProducts({ partnerId, signal }).then((products) =>
            dispatch({
                action: 'setProducts',
                value: { partnerId, products },
            })
        );

    const fetchPartnerStripeStatus = ({ partnerId, signal }) =>
        client.fetchPartnerStripeStatus({ partnerId, signal }).then((status) =>
            dispatch({
                action: 'setStripeStatus',
                value: { partnerId, status },
            })
        );

    const fetchPaymentMethods = () =>
        client.fetchPaymentMethods().then((paymentMethods) => {
            dispatch({
                action: 'setPaymentMethods',
                value: paymentMethods,
            });
            return paymentMethods;
        });

    const getPreference = (preferencePath) =>
        get(state.preferences, preferencePath);

    const login = async ({ email, password }) =>
        client
            .login({ email, password })
            .then(() => dispatch({ action: 'setLogin', value: true }))
            .catch((err) => {
                dispatch({ action: 'setLogin', value: false });
                dispatch({ action: 'setUser', value: null });
                throw err;
            });

    const logout = async () =>
        client.logout().finally(() => {
            dispatch({ action: 'setLogin', value: false });
            dispatch({ action: 'setUser', value: null });
        });

    const refreshPreferences = async () =>
        client
            .fetchPreferences()
            .then((preferences) =>
                dispatch({ action: 'setPreferences', value: preferences })
            );

    const refreshUser = async () =>
        client
            .fetchUser()
            .then((user) => dispatch({ action: 'setUser', value: user }))
            .catch(() => {
                dispatch({ action: 'setLogin', value: false });
                dispatch({ action: 'setUser', value: null });
            });

    const removePaymentMethod = async (id) =>
        client.removePaymentMethod(id).then(() => fetchPaymentMethods());

    const savePreference = async (path, value) => {
        dispatch({
            action: 'setPreference',
            value: { path, value },
        });
        return client
            .savePreference({ [path]: value })
            .then((updatedPreferences) =>
                dispatch({
                    action: 'setPreferences',
                    value: updatedPreferences,
                })
            )
            .catch(refreshPreferences);
    };

    const searchUsers = async ({ query, ...rest }) =>
        client.searchUsers({
            email: query,
            name: query,
            phone: query,
            ...rest,
        });

    const signUp = async ({ email, invite, name, password, phone }) =>
        client
            .signUp({ invite, name, password, phone })
            .then(() => login({ email, password }));

    const updatePartner = async (partnerDetails) =>
        client.updatePartner(partnerDetails).then(refreshUser);

    const updateProfile = async ({ email, name, phone }) =>
        client.updateUser({ email, name, phone }).then(refreshUser);

    return {
        state,
        dispatch,
        addPaymentMethod: client.addPaymentMethod,
        addTeamMember: client.addTeamMember,
        fetchInviteDetails: client.fetchInviteDetails,
        fetchJobArchive: client.fetchJobArchive,
        fetchOutsourcingArchive: client.fetchOutsourcingArchive,
        fetchPartner: client.fetchPartner,
        fetchPartners: client.fetchPartners,
        fetchPartnerProducts,
        fetchPerformanceReport: client.fetchPerformanceReport,
        fetchPartnerStripeStatus,
        fetchPartnerStripeURL: client.fetchPartnerStripeURL,
        fetchPaymentMethods,
        fetchSchedulingTemplate: client.fetchSchedulingTemplate,
        fetchSchedulingTemplateAvailability:
            client.fetchSchedulingTemplateAvailability,
        fetchTeam: client.fetchTeam,
        fetchTeamMember: client.fetchTeamMember,
        getPreference,
        joinWaitlist: client.joinWaitlist,
        login,
        logout,
        refreshPreferences,
        refreshUser,
        removePaymentMethod,
        removeTeamMember: client.removeTeamMember,
        requestReset,
        savePreference,
        searchUsers,
        searchPartners: client.searchPartners,
        signUp,
        updatePartner,
        updateProfile,
        updateTeamMember: client.updateTeamMember,
    };
};

export default { useAuth, AuthProvider };
